.hero {

  .link-text3.color-white:is(:hover,:focus) {
    color: #fff;
  }

  .card-item {
    width: 100%;
    height: 400px;
    background-repeat: no-repeat;
    background-size: cover;
    
  }

  .card-content {
    height: 100%;
    padding: 30px;
  }

  .card-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: cover;
  }

  .card {
    
    border: 1px solid rgba(0, 0, 0, 0.6);
    color: #000;
    display: flex;
    flex-shrink: 0;
    flex-direction: column;
    overflow: hidden;
    position: relative;
    vertical-align: top;
    height: 100%;
    width: 100%;
  }

}

.slide-5 {
  color: #fff;
  .title-wrap {
    display: block;
  }
  .theme-accent-bg {
    background: url("../../images/reskin/slides/slide5-bg.png") repeat 0 0;
  }
  @media (max-width: 767px) and (orientation: portrait) {
    .bg-art, .hero-bg, .bg-art>*, .hero-bg>* {
      height: auto;
    }
  }
  &.card-item {
    @media (max-width: 1024px) {
      height: 858px;
      background-size: 100% auto;
    }
    .heading-lg {
      color: #fff;
      font-size: 48px;
    }
    .subheading {
      font-size: 24px;
    }
  }
  .card-content {
    @media (max-width: 1024px) {
      height: unset;
      margin-top: 60vw;
      color: white;
      h1 {
        color: white;
        font-size: 36px;
      }
    }
  }
  .card-background {
    overflow: hidden;
    background-image: url(../../images/reskin/hero.jpg);
    @media (max-width: 1024px) {
      background-size: auto 60vw;
      background-position: top center;
      background-image: url(../../images/reskin/slides/slide1-mobile.jpg);
    }
    .mobile-bg {
      position: absolute;
      top: 30vw;
      bottom: -2px;
      left: 0;
      width: 100%;
      background-size: 100% auto;
      background-position: top center;
      background-image: url('../../images/reskin/HeroBgMobile.svg');
      @media (min-width: 1024px) {
        display: none;
      }
    }
  }
}

.slide-1 {
  &.card-item {
    @media (max-width: 1024px) {
      height: 858px;
      background-size: 100% auto;
    }
    .heading-lg {
      color: #1E2328;
    }
  }
  .card-content {
    @media (max-width: 1024px) {
      height: unset;
      margin-top: 60vw;
      color: white;
      h1 {
        color: white;
        font-size: 36px;
      }
    }
  }
  .card-background {
    overflow: hidden;
    background-image: url(../../images/reskin/hero.jpg);
    @media (max-width: 1024px) {
      background-size: auto 60vw;
      background-position: top center;
      background-image: url(../../images/reskin/slides/slide1-mobile.jpg);
    }
    .mobile-bg {
      position: absolute;
      top: 30vw;
      bottom: -2px;
      left: 0;
      width: 100%;
      background-size: 100% auto;
      background-position: top center;
      background-image: url('../../images/reskin/HeroBgMobile.svg');
      @media (min-width: 1024px) {
        display: none;
      }
    }
  }
}

.slide-2 {
  color: white;
  width: 100%;
  a {
    color: white;
  }
  h2 {
    font-size: clamp(16px, 1.3vw, 22px);
    letter-spacing: -.007em;
    line-height: 1.3;
    font-family: font-bold, sans-serif;
    .large {
      line-height: 1.1;
    }
  }
  .large {
    font-size: clamp(32px, 2.4vw, 42px);
    letter-spacing: -.03em;
    font-family: font-bold, sans-serif;
  }
  .med {
    // font-size: 32px;
    font-size: clamp(22px, 1.8vw, 32px);
    letter-spacing: -.03em;
    font-family: font-bold, sans-serif;
  }
  .small {
    font-size: 12px;
  }
  .slide-card {
    text-align: left;
    max-width: 300px;
    @media (min-width:1024px) {
      max-width: unset;
      &.card1 {
        width: 30%;
      }
      &.card2 {
        width: 35%
      }
      &.card3 {
        width: 35%;
      }
    }
  }
  .card-content {
    padding: 60px 110px;
    justify-content: center;
    @media (max-width:1024px) {
      padding: 20px 20px 100px;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
    }
  }
  .cards-image {
    width: 90%;
    max-width: 260px;
    margin-left: -5px;
    margin-top: 8px;
  }
  .card-image {
    max-width: 95px;
    margin-top: 8px;
  }
  .slide-plus {
    position: relative;
    height: 60px;
    svg {
      width: 30px;
    }
    @media (min-width: 1025px) {
      width: 80px;
      height: unset;
      svg {
        margin-top: -40px;
      }
    }
    
  }
  .slide-background {
    position: absolute;
    overflow: hidden;
    border-radius: 16px;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #009FDB;
  }
  .ribbon {
    position: absolute;
     bottom: 0;
     right: 0;
     width: 120%;
     max-width: 440px;
     transform: translateX(20%);
  }
}

