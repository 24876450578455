.basic-slide {
  height: 400px;
  @media (max-width:1024px) {
    height: 858px;
  }

  h3 {
    color: #009FDB;
    font-size: 40px;
    line-height: 1;
    margin-bottom: 20px;
  }

  &.theme-accent-bg {
    background-color: #f3f4f6;
    border-radius: 16px;
  }

  .hero-panel-content {
    @media (max-width:1024px) {
      display: block;
      & > div:first-child {
        height: 60vw;
        max-height: 450px;
        max-width: 100%;;
      }
      & > div:last-child {
        max-width: 100%;;
      }
    }
  }
  
  #embrace-exBG4 {
    background-image: url("../../images/page-heading/Embrace-vert-01.svg");
    background-position: bottom left;
    background-repeat:no-repeat;
    background-size:cover;
    height:400px; 
    width:90px; 
    position:absolute; 
    left: 0;
    bottom: 0;
    margin-left:-1px;
  }
  
  #exBG4 {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
  
  .slide3-image {
    background-image:url("../../images/reskin//slides/slide3.jpg");
  }
  .slide4-image {
    background-image:url("../../images/reskin//slides/slide4.jpg");
  }
  .slide5-image {
    background-image:url("../../images/reskin//slides/slide5-1.gif");
    border-radius: 0;
    
    #embrace-exBG4 {
      background-image: url("../../images/reskin/slides/embrace-ex5.png");
      width: 262px;
    }
  }

  /* mobile portrait */
  @media (max-width:1024px) {
    .slide3-image {
      background-image:url("../../images/reskin//slides/slide3-mobile.jpg");
    }
    .slide4-image {
      background-image:url("../../images/reskin//slides/slide4-mobile.jpg");
    }
    #exBG4 {
        margin-left:0;
        max-width: 100%;
        flex-basis: 100%;
    }
    #embrace-exBG4 {
        background-image: url("../../images/page-heading/Embrace-hrz-01.svg");
        background-size: contain;
        background-position: -2px 100%;
        height:100%; 
        width: calc(100vw + 4px); 
        position:absolute; 
        left: 0;
        bottom:0;
        margin-bottom:-1px
    }
    .slide5-image #embrace-exBG4 {
      position: absolute;
      top: 14vw;
      bottom: -2px;
      left: 0;
      width: 100%;
      background-size: 100% auto;
      background-position: top center;
      background-image: url('../../images/reskin/HeroBgMobile.svg');
      @media (min-width: 1024px) {
        display: none;
      }
    }
  
  }

}

