.wcf-form {
  .select-container {
    padding-top: 2px;
  }
  .file-label {
    font-size: 16px;
  }
  .error .formfield-msg
  {
    margin-top: 6px;
    opacity: 1;
    max-height: unset;
    min-height: unset;
    color: #c70032;
  }

  .custom-label {
    margin-bottom: -22px;
  }
  .formfield-label.error:after {
    display: none;
  }

  // .date-picker input.error {
  //   border-color: var(--textfield-error-border, #c70032);
  // }

  .small-content {
    font-size: 1.5rem;
  }
  li {
    line-height: 1.4;
  }
}