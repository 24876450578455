.header {

  .link-text {
    text-decoration: none;
    margin-bottom: 5px;
  }
  .logo{
    background-color: transparent;
    flex-shrink: 0;
    border: 0;
  }
  .mobile-show {
    display: none;
    width: 30px;
    @media (max-width: 767px) {
      display: block;
    }
    
  }
  .mobile-hide {
    @media (max-width: 767px) {
      display: none;
    }
  }

  .search-button {
    flex-shrink: 0;
    svg {
      height: 24px !important;
      width: 24px !important;
      margin: 0px !important;
    }
  }

  .search {
    margin-left: 10px;
    position: relative;

    & > .flex {
      min-height: 36px;
    }

    .search-inner {
      margin-right: 10px;
    }
    .textfield{
      min-width: 250px;
      min-height: 36px;
      padding-right: 36px;
      @media (min-width: 650px) {
        min-width: 300px;
      }
    }
    .clear-btn {
      height: 36px;
      width: 36px;
    }
    .search-card {
      position: absolute;
      right: 32px;
      z-index: 30;
      width: calc(100% - 32px);
      @media (min-width: 390px) {
        width: 110%;
      }
      margin-top: 10px;
      button {
        text-align: left;
        margin-bottom: 2px;
      }
    }
    .search-footer {
      background-color: #606060;
      color: white;
    }
    .icon {
      margin-left: 10px;
    }
  }
}