.form {
  .select-container {
    padding-top: 2px;
  }
  .file-label {
    font-size: 16px;
  }
  .error .formfield-msg
  {
    margin-top: 6px;
    opacity: 1;
    max-height: unset;
    min-height: unset;
    color: #c70032;
  }
}