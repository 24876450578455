.Subcategories {
  .test {
    column-count: 2;
  }
  .columns {
    column-count: 3;

    &.count-1 {
      column-count: 1;
      width: 50%;
    }

    @media(max-width: 767px) {
      column-count: 1;
    }
  }
}