.complete {
  
  .complete-content {
    background: #F3FAFF;
    border-radius: 16px;
  }
  @media (max-width: 767px) {
    .heading-sm {
      font-size: 2rem;
    }
    .heading-xs {
      font-size: 1.6rem;
    }
    .type-sm {
      font-size: 1.5rem;
    }
    .type-xs {
      font-size: 1.4rem;
    }
  }
}