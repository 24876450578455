.categories {

  @media (max-width:767px) {
    h2 {
      font-size: 22px;
    }
  }

  .image-wrapper {
    @media (max-width:767px) {
      width: 60px;
      flex-shrink: 0;
    }
  }
  .card-text {
    width: 100%;
    @media (max-width: 767px) {
      width: auto;
    }
  }
  .icon {
    height: 80px;
    width: auto;
    @media (max-width: 767px) {
      height: 60px;
      margin-bottom: 0;
      margin-right: 10px;
    }
  }
  .sm-card {
    min-width: 215px;
  }
  .columns {
    column-count: 2;

    @media(max-width: 767px) {
      column-count: 1;
    }
  }
  .popover-active {
    text-decoration: none !important;
  }
}