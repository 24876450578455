.marketing-materials {
  .marketing-card {
    background-image: url(../../images/reskin/BlueEmbracePatternBG.png);
    background-position: center;
    background-size: cover;    border-radius: 16px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 261px;
    text-align: center;
    padding: 0 30px;
    h3,h4 {
      color: white;
    }

    @media (max-width: 767px) {
      height: 100px;
    }
  }
}