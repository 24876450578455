// target root modal to ensure smaller child modals don't impact width
div[aria-label="base modal"] {
  .duc-modal-content {
    max-width: 100vw;
  }
}

.mar-b-md {
  position: relative;
}
.modal-body {
  // position: relative;
  z-index: 21;

}

.modal-background {
  height: 100%;
  max-height: 800px;
  width: auto;
  position: absolute;
  bottom: -32px;
  right: -32px;

  @media (max-width:1024px) {
    height: 75%;
    max-height: 500px;
  }

  @media (max-width:767px) {
    height: 50%;
    max-height: 300px;
  }
}
.modal-background-outer {
  position: relative;
  z-index: 21;
  min-height: calc(100% - 82px);
  top: 50px;
  left: 0;
  width: 100%;
}

div[aria-label="modal promo aria label"] {
  .image-content {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 60%;
    text-align: center;
    color: #fff;
    
    h1 {
      font-family: font-black, sans-serif;
      font-size: 26px;
      color: #fff;
      font-weight: bold;
      
      span.heading-bump {
        font-family: font-black, sans-serif;
        color: #fff;
        display: block;
        font-size: 60px;
        line-height: 56px;
        margin-bottom: 12px;
        
        @media (max-width:620px) {
          line-height: 38px;
          font-size: 40px;
          margin-bottom: 6px;
        }  
          
        sup, span {
          color: #00388F;
        }
      }
    }
  }
}