.newsletter {
  margin-bottom: 24px;
  margin-top: 24px;
  .bg-color {
    right: calc(50% - 100px) !important;
    background-image: url('../../images/reskin/HeroBg.svg');
    background-repeat: no-repeat;
    background-position-x: right;

    @media (max-width: 767px) {
      right: unset !important;
      bottom: 0 !important;
      top: 30vw !important;
      height: unset !important;
      width: 100%;
      background-size: 100% auto;

      background-image: url('../../images/reskin/HeroBgMobile.svg');
    }
  }

  .bg-art {
    @media (max-width: 767px) {
      height: 100% !important;
    }
  }

  &.hero-panel {
    border-radius: 16px;
    height: 312px;
    @media (max-width: 767px) {
      height: unset;
    }
    h3, p {
      color: white;
    }
  }

  .newsletter-content {
    padding: 0 50px;
    @media (max-width: 1024px) {
      padding: 0 20px;
    } 
    @media (max-width: 767px) {
      padding: 20px;
    }
  }
  
  /* desktop */
  @media (min-width:1025px) {
    #exBG4 {
        background-image: url("../../images/reskin/slides/newsletter.jpg");
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
    }
  }
  /* tablet */
  @media (min-width:768px) and (max-width:1024px) {
    #exBG4 {
      background-image: url("../../images/reskin/slides/newsletter.jpg");
        background-position: center;
        background-size: cover;
    }         
  }
  /* modern mobile landscape */
  // @media (max-width:767px) and (orientation:landscape) {
  //   #exBG4 {
  //       background-image:url("../../images/page-heading/image-md-half.jpg");
  //       background-position: 50% 10%;
  //       background-size: cover;
  //   }
  // }
  /* mobile portrait */
  @media (max-width:767px){
    #exBG4 {
      background-image: url("../../images/reskin/slides/newsletter-mobile.jpg");
        background-position: center;
        background-size: cover;
        margin-left:0;
        max-width: 100%;
        flex-basis: 100%;
    }
    #embrace-exBG4 {
        background-image: url("../../images/page-heading/Embrace-hrz-01.svg");
        background-size: contain;
        background-position: -2px 100%;
        height:100%; 
        width: calc(100vw + 4px); 
        position:absolute; 
        left: 0;
        bottom:0;
        margin-bottom:-1px
    }
  }
  
}

