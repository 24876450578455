.custom-carousel.carousel.container {
  position: relative;
  padding-bottom: 0;
  max-width: 1600px;
  @media only screen and (max-width: 1024px) {
    padding: 0 0 10px 0;
  }
  .nav-prev {
    position: absolute;
    top: 0;
    left: 50px;
    height: 100%;
    z-index: 20;
  }
  .nav-next {
    position: absolute;
    top: 0;
    right: 50px;
    height: 100%;
    z-index: 20;
  }

  .nav-prev, .nav-next {
    .carousel-control span {
      background-color: rgba(255,255,255,.7);
    }
  }

  .carousel-indicators {
    bottom: 50px;
  }
}