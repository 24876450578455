.helpful-links {
  .phone-link{
    white-space: nowrap;
    font-family: font-regular, sans-serif !important;
  }
  .custom-link {
    font-family: font-regular, sans-serif !important;
  }
  .columns {
    column-count: 2;
  }

}
@media(max-width: 1024px) {
  .columns {
    column-count: 1 !important;
  }
}
