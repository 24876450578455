#scroll-indicator {
  display: none;  
  @media (max-width: 1024px) {
    position: absolute;
    height: 20px;
    width: 20px;
    top: 80vh;
    left: calc(50% - 10px);
    z-index: 3;
    display: block;
    border-bottom: 2px solid black;
    border-right: 2px solid black;
    transform: translate(-50%, 0%) rotate(45deg);
    animation: fade_move_down 3s ease-in-out infinite;
    pointer-events: none;
  }
}

@keyframes fade_move_down {
  0% {
    transform: translate(0, -10px) rotate(45deg);
    opacity: 0;
  }
  50% {
    opacity: .8;
  }
  100% {
    transform: translate(0, 10px) rotate(45deg);
    opacity: 0;
  }
}

.default-layout {
  min-height: 100vh;
}