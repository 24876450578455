.faq {
  position: relative;
  margin-bottom: 30px;
  .accordion {
    border-radius: 16px;
  }
  .faq-title {
    margin-bottom: 50px;
  }

  a {
    white-space: nowrap;
  }

  .toggle-link {
    color: rgb(0, 87, 184);
    position: absolute;
    top: 60px;
    right: 40px;
    z-index: 10;
    @media (min-width: 767px) {
      top: 40px;
    }
    @media (min-width: 1024px) {
      top: 50px;
    }
  }
}