.confirmation {

  .confirmation-content {
    max-width: 600px;

    .font-bold a {
      font-weight: bold;
    }
  }

  .text-danger {
    color: red;
  }

  background: #F3FAFF;
  border-radius: 16px;
  
    @media (max-width: 767px) {
      .heading-sm {
        font-size: 2rem;
      }
      .heading-xs {
        font-size: 1.6rem;
      }
      .type-sm {
        font-size: 1.5rem;
      }
      .type-xs {
        font-size: 1.4rem;
      }
    }

    .link-text {
      color: var(--link-text3-default-color, #0057b8);
      text-decoration: none;
      font-family: font-regular, sans-serif;
      
      &:hover {
        text-decoration: underline;
      }
    }
}

.modal-button-container {
  @media (max-width: 767px) {
   flex-wrap: wrap;
    h4 {
      width: 100%;
      margin-bottom: 8px;
    }
  }
}