.acc-breadcrumbs {
  display: flex;
  gap: 8px;
  margin-bottom: 5px;
  a {
    white-space: nowrap;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='6.553' height='11.109' viewBox='0 0 6.553 11.109'%3E%3Cpath id='Path_223' data-name='Path 223' d='M.644,0,0,.643,4.556,5.2,0,9.758l.645.645L5.2,5.846,5.846,5.2Z' transform='translate(0.354 0.353)' fill='%230057b8' stroke='%230057bf' stroke-width='0.5'%3E%3C/path%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: 0 50%;
    padding-left: 16px;

    &.disabled {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='6.553' height='11.109' viewBox='0 0 6.553 11.109'%3E%3Cpath id='Path_223' data-name='Path 223' d='M.644,0,0,.643,4.556,5.2,0,9.758l.645.645L5.2,5.846,5.846,5.2Z' transform='translate(0.354 0.353)' fill='%23000000' stroke='%23000000' stroke-width='0.5'%3E%3C/path%3E%3C/svg%3E");
      color: #000;
      text-decoration: none;
      pointer-events: none;
    }
  }
  // a:first-of-type {
  //   background: none;
  //   padding-left: 0;
  // }
  @media screen and (max-width: 768px) {
    // justify-content: center;
    flex-wrap: wrap;
    gap: 5px;
  }
}

.breadcrumbs-outer {
  .icon-image {
    width: 46px;
  }
  .icon-headline {
    align-items: center;
    gap: 8px;
  }
}